import React from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import MatchWaiting from "@/game-rocket/components/MatchWaiting.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function InGame() {
  const { t } = useTranslation();
  const {
    rocket: { liveGame },
  } = useSnapshot(readState);
  const gameUsername = "";
  if (!liveGame) return <MatchWaiting gameUsername={gameUsername} />;
  return <div>{t("", "You are playing a rocket league match")}</div>;
}

export function meta() {
  return {
    title: ["", ""],
    description: ["", ""],
  };
}
